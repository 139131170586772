import { Invoice, InvoiceState, InvoiceRouteProps} from "./Invoice";


export class InvoiceByUrlRoute extends Invoice<InvoiceRouteProps, InvoiceState> {

	constructor (props: InvoiceRouteProps) {
		super(props);
		this.state = {
		};
	}
	
	componentDidMount () {
		let invoiceUrl = undefined;
		const { params } = (this.props as InvoiceRouteProps).match;
		invoiceUrl = params.invoiceUrl;
		if (invoiceUrl !== undefined) {
			this.fetchInvoiceByUrl(invoiceUrl).then (response => this.handleFetchInvoiceResponse(response)).catch(response => this.handleFetchInvoiceError(response));
		}
		else {
			this.setState ({
				queryState: "notFound"
			});
		}
	}		

}
